function scrollToAnchor(anchorId) {
    var target = document.querySelector(anchorId);
    if (target) {
      window.scrollTo({
        top: target.offsetTop -60,
        behavior: "smooth"
      });
    }
  }



function openPopup(event) {
  event.preventDefault();
  // Afficher la div pour le fond semi-transparent
  document.getElementById("overlay").style.display = "block";
  // Afficher la div pour la popup
  document.getElementById("popup").style.display = "flex";
}

function closePopup() {
  // Cacher la div pour le fond semi-transparent
  document.getElementById("overlay").style.display = "none";
  // Cacher la div pour la popup
  document.getElementById("popup").style.display = "none";
}



function triggerElements() {
  const rect = installPhotos.getBoundingClientRect();

  if(rect.top > window.innerHeight || rect.bottom < 0){
      installPhoto1.classList.remove('rotate')
      installPhoto3.classList.remove('rotate')
  }else if(( rect.bottom > 0 &&  rect.top < 0)|| rect.bottom < window.innerHeight){
    installPhoto1.classList.add('rotate')
    installPhoto3.classList.add('rotate')
  }
  const ecolePhoto = document.getElementById("photoEcole");
  const animPhoto = document.getElementById("photoAnim");
  const inscriptionPhoto = document.getElementById("photoInscription");
  apparition(ecolePhoto);
  apparition(animPhoto);
  apparition(inscriptionPhoto);

}
setInterval(triggerElements, 150);















class Actu{
  constructor(date, annonce, title, text) {
    this.date = date;
    this.annonce = annonce;
    this.title = title;
    this.text = text;
  }
}


function apparition(photo){
  const rect = photo.getBoundingClientRect();
  if( rect.top < window.innerHeight - 150){
    photo.classList.add('showPhoto')
    photo.classList.add('showPhoto')
  }
}


window.addEventListener("load", () => {
  
  const installPhotos = document.getElementById("installPhotos");
  const installPhoto1 = document.querySelectorAll('.installPhoto1');
  const installPhoto3 = document.querySelectorAll('.installPhoto3');

  const monElement = document.getElementById("tcpo");
  const seuilScroll = 150;
  let br = 1;
  window.addEventListener('scroll', function() {
    if (window.scrollY > seuilScroll) {
      monElement.style.filter = 'brightness(0.8)';
    }else{
      monElement.style.filter = 'brightness(1)';
    }
  });




let actus = [];
let act1 = new Actu("Inscriptions","Les inscriptions pour la saison 2024/2025 sont en cours. <br>Consultez la rubrique Inscription pour plus d'informations.", `<a onclick="scrollToAnchor('#inscription')" class='btn btn__actu'>Inscription</a>`, "")
let act2 = new Actu("Ecole de tennis","Les inscriptions pour l'école de tennis de la saison 2024/2025 sont en cours. <br> Consultez la rubrique Ecole de tennis pour plus d'informations.",`<a onclick="scrollToAnchor('#ecole')" class='btn btn__actu'>Ecole de tennis</a>`,"")
let act3 = new Actu("Le Tennis Club de Précy-sur-Oise","Le TCPO en quelques mots. <br> Consultez le livret pour plus d'information sur le club.",`<a target="_blank" href="/src/datas/livret.pdf" class="btn btn__actu">Livret TCPO</a>`,"")
actus.unshift(act1)
actus.unshift(act3)
actus.unshift(act2)

actus.forEach(function callback(actu, index){

  const newDiv = document.getElementById("actu"+index)
  
  const titre = document.createElement("h2");
  titre.innerHTML = actu.date;
  const annonce = document.createElement("p");
  annonce.innerHTML = actu.annonce
  const title = document.createElement("span");
  title.innerHTML = actu.title
  const text = document.createElement("p");
  text.innerHTML = actu.text
  newDiv.appendChild(titre);
  newDiv.appendChild(annonce);
  newDiv.appendChild(title);
  newDiv.appendChild(text);

})

const option0 = document.getElementById('option0');
const option1 = document.getElementById('option1');
const option2 = document.getElementById('option2');

let selected=document.getElementById("actu0");

const actu0 = document.getElementById('actu0');
const actu1 = document.getElementById('actu1');
const actu2 = document.getElementById('actu2');




function changeActu(actu , selected){

  selected.classList.remove("show")
  selected.classList.add("unshow")
  actu.classList.add("show")
  setTimeout(function() {
    selected.classList.remove("unshow")
  }, 1000);
  return actu
}

option0.addEventListener('click', () => {
  selected=changeActu(actu0,selected)
});

option1.addEventListener('click', () => {
  selected=changeActu(actu1,selected)
});

option2.addEventListener('click', () => {
  selected=changeActu(actu2,selected)
});

let intervalId = setInterval(function() {
  if(option0.checked){
    selected=changeActu(actu1,actu0)
    option0.checked=false
    option1.checked=true
  }else if(option1.checked){
    selected=changeActu(actu2,actu1)
    option1.checked=false
    option2.checked=true
  }else if(option2.checked){
    selected=changeActu(actu0,actu2)
    option2.checked=false
    option0.checked=true
  }
}, 7000);




});
